import classNames from 'classnames';
import React from 'react';
// import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import Markdown from 'react-markdown';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import Disclaimer from '../../components/Disclaimer/Disclaimer';
import Footer from '../../components/Footer/Footer';
import { HelperRow } from '../../components/HelperRow/HelperRow';
import Loading from '../../components/Loading/Loading';
import { API_KEY, API_URL, getDocuments } from '../../utils/API';
import styles from '../Article/Article.module.css';
import mdStyles from '../Article/Markdown.module.css';

const Category = () => {
  // API handling
  const slug = useLocation().pathname;
  const documentUrl = `${API_URL}/getDocumentDetail?key=${API_KEY}&slug=${slug.split('/')[2]}.html`;
  const { data } = useQuery(['documents', slug], () => getDocuments(documentUrl), {
    notifyOnChangeProps: ['error', 'data']
  });
  const helmetSlug = slug
    .split('/')[2]
    .split('-')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
  if (!data) {
    return (
      <>
        <Helmet>
          <title>{helmetSlug} - Statcare </title>
        </Helmet>
        <Loading color="#333333" />
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>{helmetSlug} - Statcare </title>
      </Helmet>
      {data && (
        <div>
          <a className={styles.category} href={data['cta_link'] + slug}>
            {data['category']}
          </a>
          {/* <Button variant="dark">REPORT A CONTENT ISSUE</Button> */}
          <Markdown className={classNames(mdStyles.h1, mdStyles.h2, mdStyles.p)}>
            {data['content']}
          </Markdown>
          <h3 className={styles.sources}>Sources : </h3>
          {data['sources'].map((source) => (
            <li key={source}>
              <a className={styles.source} href={source} key={source}>
                {source}
              </a>
            </li>
          ))}
          <h5 className={styles.callToAction}>{data['call_to_action']}</h5>
          <HelperRow text="Let us help you today!" slug={slug} />
          <Disclaimer />
          <Footer />
        </div>
      )}
    </>
  );
};

export default Category;
