import PropTypes from 'prop-types';
import React from 'react';
import nextId from 'react-id-generator';

const Loading = ({ color }) => {
  const htmlId = nextId();

  return (
    <div id={htmlId} className="loader">
      <div className="loader__wheel" />
      <div className="loader__text" />

      <style jsx>{`
        #${htmlId}.loader {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 5rem;
        }

        #${htmlId} .loader__wheel {
          animation: spin 1s infinite linear;
          border-left: 4px solid ${color};
          border-radius: 50%;
          height: 4rem;
          width: 4rem;
          margin-bottom: 1rem;
        }

        #${htmlId} .loader__text::after {
          color: ${color};
          content: 'Loading';
          animation: load 2s linear infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes load {
          0% {
            content: 'Loading';
          }
          33% {
            content: 'Loading.';
          }
          67% {
            content: 'Loading..';
          }
          100% {
            content: 'Loading...';
          }
        }
      `}</style>
    </div>
  );
};

Loading.propTypes = {
  color: PropTypes.string
};

export default Loading;
